<template>
  <b-modal
    id="memberChangeMajorMedicalModal"
    modal-class="client-details-modal"
    centered
    size="md"
    hide-footer
    no-close-on-backdrop
    @shown="getChangeAblePlan"
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content">
        <h6>Change Plan</h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.stop.prevent="handleSubmit(updatePlan)"
            ref="memberChangeMajorMedicalContainer"
          >
                  <div class="form-group row">
          <div class='col-12 mb-8' >
            <multiselect
              class="major-medical-plan-select"
              name="plan"
              v-model="selectedPlan"
              placeholder="Select Plan"
              :options="planList.map((type) => type.plan_pricing_id)"
              :custom-label="
                (opt) => { let p = planList.find((x) =>  x.plan_pricing_id == opt); return p.plan_name + ' - $'+p.plan_price }
              "
            />
          </div>
              <div class="form-group  mb-3 mt-3">
                <b-button
                  class="univ-btn"
                  style="margin-top: 3rem;margin-bottom: 2rem;"
                  type="submit"
                >
                  <template v-if="isUpdating"> Updating ... </template>
                  <template v-else> Change Plan </template>
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "MemberChangeMajorMedicalModal",
  props: {
    member: {
      required: true,
    },
  },
  data: () => ({
    isUpdating: false,
    selectedPlan: '',
    planList: []
  }),
  methods: {
    closeModal() {
      this.$bvModal.hide("memberChangeMajorMedicalModal");
      this.$emit("onClosedModal");
    },
    updatePlan() {
      this.isUpdating = true;
      const url = `v2/medical-plan/update-plan`;
      let loader = this.$loading.show({
        container: this.$refs.memberChangeMajorMedicalContainer,
      });
      Api.post(url,{
          policy_id: this.member.policy_id,
          plan_pricing_id: this.selectedPlan,
          plan_id: this.getPlan()
        })
        .then((res) => {
          this.$notify(res.data.message, "success");
          this.$parent.$emit('onSearch',this.$parent.$parent.filterQuery);
          this.closeModal();
        })
        .catch((err) => {
           this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdating = false;
          loader.hide();
        });
    },
      getChangeAblePlan() {
      const planUrl = `v2/medical-plan/list-eligible-plan/${this.member.policy_id}`;
      let loader = this.$loading.show({
        container: this.$refs.memberChangeMajorMedicalContainer,
      });
      Api.get(planUrl)
        .then((res) => {
          this.planList = res.data.data;
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
   getPlan() {
      let planId = ''
      let selectedPlanPricingID = this.selectedPlan;
      this.planList?.map((plan) => {
        if (plan.plan_pricing_id == selectedPlanPricingID) {
            planId = plan.plan_id;
        }
      });
      return planId;
    },
  }
};
</script>
<style>
.major-medical-plan-select {
  width: 100% !important;
}
.major-medical-plan-select .multiselect__content-wrapper {
  height: 380% !important;
}
.view-clients-modal-content .univ-btn{
  max-width: inherit;
  width: 100%;
}
</style>