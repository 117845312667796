<template>
  <!-- Modal -->
  <b-modal
      id="sendReferralLinkToUser"
      modal-class="referral-model"
      @shown="getMemberInfo()"
      centered
      size="lg"
      hide-header
      hide-footer
  >
    <div class="referral-header">
      <b-button variant="close-btn" @click="closeModal">
        <i class="fas fa-times"></i>
      </b-button>
    </div>
    <b-container fluid>
      <div class="referral-contents mt-3">
        <h5><strong>Send Referral Link</strong></h5>
        <div class="referral-message-form mt-3">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form ref="sendReferralLink">
              <div class="form-group row">
                <input-text
                    :rules="{ required: true }"
                    labelFor="name"
                    labelName="Name"
                    inputName="name"
                    :vmodel.sync="form.name"
                    formGroupClass="col-xxl-6 mb-3"
                />
                <input-text
                    :rules="{ required: true, email: true}"
                    labelFor="email"
                    labelName="Email"
                    inputName="email"
                    :vmodel.sync="form.email"
                    formGroupClass="col-xxl-6 mb-3"
                    inputType="email"
                />

                <div class="col-xxl-12 mb-3">
                  <validation-provider
                      name="Message"
                      v-slot="{ errors }"
                  >
                    <label>
                      Message
                    </label>
                    <ckeditor
                        :editor="editor"
                        v-model="form.message"
                        :config="editorConfig"
                    />
                    <div class="invalid-block invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-xxl-5 mb-4">
                  <b-button class="univ-btn" type="button" :disabled="isSend" @click="handleSubmit(sendEmail)">
                    <template v-if="isSend"> Sending ... </template>
                    <template v-else> Send </template>
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "SendReferralLinkToUser",
  props: {
    member: {
      required: true
    }
  },
  data: () => ({
    editor: ClassicEditor,
    editorConfig: Helper.simpleEditorConfig(),
    form: {
      email: "",
      message: "",
      name: "",
      userid: ''
    },
    isSend: false,
  }),
  methods: {
    reset() {
      this.form = {
        email: "",
        message: "",
        name: ""
      };
      this.$refs.observer.reset();
    },
    closeModal() {
      this.reset();
      this.$emit("onCloseModal");
      this.$bvModal.hide("sendReferralLinkToUser");
    },
    getMemberInfo () {
      let app = this
      app.form.userid = app.member.user_id
    },
    sendEmail() {
      const url = `v2/send-referral-url`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.sendReferralLink,
      });
      Api.post(url, this.form)
          .then((res) => {
            this.$notify(res.data.message);
            this.closeModal();
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            this.isSend = false;
            loader.hide();
          });
    },
  },
};
</script>
