<template>
  <b-modal
    id="policyDateModal"
    modal-class="client-details-modal"
    centered
    size="md"
    hide-footer
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content">
        <h6>Effective Policy Date</h6>
        <form @submit.stop.prevent="updateEffectivePolicyDate()">
          <div class="row">
            <div class="col-md-12">
              <date-picker
                class="w-100"
                type="month"
                placeholder="Choose policy date"
                v-model="effective_date"
              ></date-picker>
              <span class="error_message" v-if="hasError"
                >Please Select Date</span
              >
            </div>
          </div>
          <div class="row justify-content-end mt-2">
            <div class="col-md-5">
              <button type="submit" class="btn univ-btn btn-secondary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";
export default {
  name: "EffectivePolicyDateModal",
  data: () => ({
    isSend: false,
    effective_date: "",
    hasError: false,
  }),
  props: {
    member: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("policyDateModal");
      this.$emit("onClosedModal");
    },
    updateEffectivePolicyDate() {
      if (this.effective_date) {
        let loader = this.$loading.show();
        let path = "v2/update-effective-date";
        Api.put(path, {
          policy_id: this.member.policy_id,
          effective_date: this.formattedDate(this.effective_date),
        })
          .then((res) => {
            this.$notify(res.data.message, "success");
            this.$bvModal.hide("policyDateModal");
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            loader.hide();
          });
      } else {
        this.hasError = true;
      }
    },
    formattedDate(date) {
      return moment(date).format("YYYY-MM");
    },
  },
  watch: {
    effective_date: function (dt) {
      if (!dt) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    },
  }
};
</script>

<style scoped>
.univ-btn {
  padding: 10px;
}
.error_message {
  color: #dc3545;
}
</style>