<template>
  <b-modal
    id="memberSendLoginEmailModal"
    modal-class="client-details-modal"
    centered
    size="lg"
    hide-footer
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content">
        <h6>Send Registration & Login info Email Member</h6>
        <div class="addMessage-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(sendEmail)"
              ref="sendLoginEmailContainer"
            >
              <div class="form-group row">
                <input-text
                  labelFor="member_email"
                  labelName="Email"
                  inputName="email"
                  :vmodel.sync="member.user_email"
                  readonly
                  formGroupClass="col-xxl-6 mb-3"
                />

                <input-text
                  :rules="{ required: true }"
                  labelFor="subject"
                  labelName="Subject"
                  inputName="subject"
                  :vmodel.sync="form.subject"
                  formGroupClass="col-xxl-12 mb-3"
                />

                <div class="col-xxl-12 mb-3">
                  <label>Message </label>
                  <ckeditor
                    :editor="editor"
                    v-model="form.message"
                    :config="editorConfig"
                  />
                  <div class="invalid-block invalid-feedback"></div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-xxl-5 mb-4">
                    <b-button class="univ-btn" type="submit" :disabled="isSend">
                      <template v-if="isSend"> Sending ... </template>
                      <template v-else> Send </template>
                    </b-button>
                  </div>
                </div>
                <div class="col-xxl-12 mb-3 modal-inner-bg">
                  <label for="">From:</label>
                  <p>
                    <strong> {{ rep.name }}</strong>
                  </p>
                  <p v-if="rep.code">{{ rep.code }}</p>
                  <p>{{ rep.email }}</p>
                  <p>{{ rep.phone }}</p>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "MemberSendLoginEmailModal",
  data: () => ({
    editor: ClassicEditor,
    editorConfig: Helper.simpleEditorConfig(),
    form: {
      user_id: "",
      subject: "",
      message: "",
    },
    isSend: false,
  }),
  props: {
    member: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  methods: {
    reset() {
      this.form = {
        user_id: "",
        subject: "",
        message: "",
      };
      this.$refs.observer.reset();
    },
    closeModal() {
      this.$bvModal.hide("memberSendLoginEmailModal");
      this.$emit("onClosedModal");
    },
    sendEmail() {
      const url = `v2/send-member-registration-login-information`;
      this.form.user_id = this.member.user_id;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.sendLoginEmailContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.reset();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
  },
};
</script>
<style >
.ck-content {
  height: 200px;
}
</style>