<template>
  <b-modal
    id="approvePlanModal"
    modal-class="client-details-modal"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content">
        <h6>Approve/Reject Policy & Plans</h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.stop.prevent="handleSubmit(updatePlan)"
            ref="approvePlanModal"
          >
                  <div class="form-group row">
          <div class='col-xxl-8 col-xl-8 col-lg-8 col-md-8 mb-8' >
            <div>
              <b-form-radio v-model="is_approved" value="1" name="radio-size" >&nbsp; Approve</b-form-radio>
              <b-form-radio  v-model="is_approved" name="radio-size" value="0"  >&nbsp; Reject</b-form-radio>
            </div>
          
          </div>
              <div class="form-group  mb-3 mt-3">
                <b-button
                  class="univ-btn"
                  type="submit"
                >
                  <template v-if="isUpdating"> Updating ... </template>
                  <template v-else> Submit</template>
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "ApprovePlanModal",
  props: {
    member: {
      required: true,
    },
  },
  data: () => ({
    isUpdating: false,
    is_approved: ''
  }),
  methods: {
    closeModal() {
      this.$bvModal.hide("approvePlanModal");
      this.$emit("onClosedModal");
    },
    updatePlan() {
      this.isUpdating = true;
      const url = `v2/approve-disapprove-policy`;
      let loader = this.$loading.show({
        container: this.$refs.approvePlanModal,
      });
      Api.post(url,{
          policy_id: this.member.policy_id,
         is_approved : this.is_approved
        })
        .then((res) => {
          this.$notify(res.data.message, "success");
          this.$parent.$emit('onSearch',this.$parent.$parent.filterQuery);
          this.closeModal();
        })
        .catch((err) => {
           this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdating = false;
          loader.hide();
        });
    },
  }
};
</script>
<style scoped>
.major-medical-plan-select {
  width: 100%;
}
.major-medical-plan-select .multiselect__content-wrapper {
  height: 100px !important;
  max-height: 100px !important;
}
</style>