<template>
  <b-modal
    id="memberSignatureEmailModal"
    modal-class="client-details-modal"
    centered
    size="lg"
    hide-footer
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button
          variant="close-btn"
          @click="closeModal"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid ref="resendSignatureContainer">
      <div class="view-clients-modal-content">
        <h6>
          Re-Send Signature # {{ member ? member.policy_id : "" }} Receipt
        </h6>
        <button
          type="button"
          class="btn univ-btn btn-secondary"
          @click.prevent="sendSignatureEmail"
        >
          {{
            isSend
              ? "Re-Sending Signature Receipt"
              : "Re-Send Signature Receipt"
          }}
        </button>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "MemberSignatureEmailModal",
  data: () => ({
    isSend: false,
  }),
  props: {
    member: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("memberSignatureEmailModal");
      this.$emit("onClosedModal");
    },
    sendSignatureEmail() {
      const url = `v2/send-signature-and-upload-reminder-email?policy_id=${this.member.policy_id}&type=signature`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.resendSignatureContainer,
      });
      Api.post(url)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
  },
};
</script>
<style scoped>
.univ-btn {
  padding: 10px;
}
</style>