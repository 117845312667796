var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "policyDateModal",
      "modal-class": "client-details-modal",
      "centered": "",
      "size": "md",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('h6', [_vm._v("Effective Policy Date")]), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.updateEffectivePolicyDate();
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "month",
      "placeholder": "Choose policy date"
    },
    model: {
      value: _vm.effective_date,
      callback: function callback($$v) {
        _vm.effective_date = $$v;
      },
      expression: "effective_date"
    }
  }), _vm.hasError ? _c('span', {
    staticClass: "error_message"
  }, [_vm._v("Please Select Date")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "row justify-content-end mt-2"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('button', {
    staticClass: "btn univ-btn btn-secondary",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }