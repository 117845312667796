var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "memberChangeMajorMedicalModal",
      "modal-class": "client-details-modal",
      "centered": "",
      "size": "md",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": _vm.getChangeAblePlan
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('h6', [_vm._v("Change Plan")]), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "memberChangeMajorMedicalContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.updatePlan);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-12 mb-8"
        }, [_c('multiselect', {
          staticClass: "major-medical-plan-select",
          attrs: {
            "name": "plan",
            "placeholder": "Select Plan",
            "options": _vm.planList.map(function (type) {
              return type.plan_pricing_id;
            }),
            "custom-label": function customLabel(opt) {
              var p = _vm.planList.find(function (x) {
                return x.plan_pricing_id == opt;
              });

              return p.plan_name + ' - $' + p.plan_price;
            }
          },
          model: {
            value: _vm.selectedPlan,
            callback: function callback($$v) {
              _vm.selectedPlan = $$v;
            },
            expression: "selectedPlan"
          }
        })], 1), _c('div', {
          staticClass: "form-group  mb-3 mt-3"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          staticStyle: {
            "margin-top": "3rem",
            "margin-bottom": "2rem"
          },
          attrs: {
            "type": "submit"
          }
        }, [_vm.isUpdating ? [_vm._v(" Updating ... ")] : [_vm._v(" Change Plan ")]], 2)], 1)])])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }