<template>
  <b-modal
    id="memberPlatformUpdateModal"
    modal-class="client-details-modal platform-update"
    centered
    size="md"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="view-clients-header">
        <h4>Update Platform</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content p-4">
        <label class="mb-2"><strong>Platform</strong></label>
        <multiselect
          name="plan"
          v-model="selectedPlatform"
          placeholder="Choose Platform"
          :options="platforms.map((type) => type.domain)"
          :custom-label="(opt) => platforms.find((x) => x.domain == opt).name"
          :max-height="130"
          :searchable="true"
        />
      </div>
      <div class="form-group text-center mb-3 mt-3">
        <b-button class="univ-btn" type="submit" @click="updatePlatform">
          <template v-if="isUpdate"> Updating ... </template>
          <template v-else> Update</template>
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "MemberPlatformUpdateModal",
  props: {
    member: {
      required: true,
    },
  },
  data: () => ({
    isUpdate: false,
    platforms: [],
    selectedPlatform: null,
  }),
  methods: {
    closeModal() {
      this.$bvModal.hide("memberPlatformUpdateModal");
      this.$emit("onClosedModal");
    },
    getPlatforms(agentId) {
      Api.get("v2/platform/get?agent_id=" + agentId).then((res) => {
        this.platforms = res.data.data.platform_link;
      });
    },
    updatePlatform() {
      this.isUpdate = true;
      const url = "v2/platform/update";
      let loader = this.$loading.show({
        container: this.$refs.downloadPaymentHistoryContainer,
      });
      Api.put(url, {
        policy_id: this.member.policy_id,
        platform_url: this.selectedPlatform,
      })
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
        });
    },
  },
  watch: {
    member: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.agent_id) this.getPlatforms(val.agent_id);
      },
    },
  },
};
</script>
<style scoped>
.platform-update .modal-content .modal-body {
  min-height: 300px !important;
}
.multiselect {
  width: auto !important;
  margin: auto !important;
}
</style>
