<template>
  <b-modal
      id="memberPlanTerminationModel"
      modal-class="client-details-modal"
      size="lg"
      hide-footer
      @shown="getTermDate()">
    <template #modal-header>
      <div class="view-clients-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid ref="planTerminationContainer">
      <div class="view-clients-modal-content">
        <h5>Terminate Plan </h5><hr/>
        <div class="addMessage-form">
          <div>
            <label class="required">Term Effective Date </label>
            <select v-model="term_date"
                    :class="{'form-invalid': $v.term_date.$error, 'is-valid': term_date &&  !$v.term_date.$error}"
                    class="custom-select  mt-2">
              <option value="" disabled> Select Date</option>
              <option  v-for="(dateList, index) in term_dates" :key="index">{{dateList}}</option>
            </select>
          </div>
          <span :class="{'fa fa-check tick-icon': term_date &&  !$v.term_date.$error}"></span>
          <div class="form-error text-left ml-3 mt-2"  v-if="!$v.term_date.required && $v.term_date.$error">
            Date is required
          </div>
        </div>
      </div>
      <div class="mt-3">
        <label class="required"> Select Plan(S)</label>
        <div v-if="member">
          <div v-for="(option, index) in member.plan_details" :key="index">
            <input type="checkbox"
                   class="mr-3 check-with-label"
                   :class="{'custom-check': $v.plan_id.$error}"
                   v-model="plan_id"
                   v-if="option.plan_status  == 'ACTIVE' && option.is_assoc == 0"
                   :value="option.plan_id" />
            <label  style="margin-left: 8px;"
                    class="label-for-check"
                   :class="{'form-error': $v.plan_id.$error  }"
                   v-if="option.plan_status  == 'ACTIVE' && option.is_assoc == 0">{{ option.plan_name_system_new }} </label>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <label class="required"> Reasons </label>
        <select v-model="term_reason"
                :class="{'form-invalid': $v.term_reason.$error, 'is-valid': term_reason &&  !$v.term_reason.$error}"
                class="custom-select mt-2">
          <option value="" disabled selected> Select Reason</option>
          <option  v-for="(reason, index) in reasons"
                   :value="{value: reason.value, text: reason.text}"
                   :key="index">{{reason.text}}</option>
        </select>
        <span :class="{'fa fa-check tick-icon': term_reason &&  !$v.term_reason.$error}"></span>
        <div class="form-error text-left ml-3 mt-2"  v-if="!$v.term_reason.required && $v.term_reason.$error">
          Reason is required
        </div>
        <div v-if="term_reason.value  == 'Others'">
         <textarea v-model="other_reason"
                   v-if="term_reason.value  == 'Others'"
                   :class="{'form-invalid': $v.other_reason.$error, 'is-valid': term_reason &&  !$v.other_reason.$error}"
                   class="mt-4 textarea"
                   placeholder="Enter Reason" />
          <span :class="{'fa fa-check tick-icon others-check-align': other_reason &&  !$v.other_reason.$error}"></span>
          <span class="form-error text-left ml-3 mt-2"
                v-if="!$v.other_reason.required && $v.other_reason.$error">
            Reason is required
          </span>
        </div>
      </div>
      <div class="row justify-content-md-end  mt-4">
        <div class="col-xl-3">
          <button type="submit"
                  @click="closeModal"
                  class="univ-btn text-center">Cancel
          </button>
        </div>
        <div class="col-xl-3">
          <button type="button"
                  @click="submitTermination"
                  class="univ-btn text-center">
            Submit
          </button>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import axios from "axios";
import Api from "@/resource/Api";
import { required } from "vuelidate/lib/validators";
export default {
  name: "MemberPlanTerminationModal",
  props: {
    member: {
      required: true,
    }
  },
  data () {
    return {
      term_dates: [],
      term_reason:"",
      other_reason: "",
      term_date: "",
      plan_id: [],
      reasons: [
        { text: "Change of Employer", value: "Change of Employer" },
        { text: "Availability of Better Product", value: "Availability of Better Product" },
        { text: "Better Service Offered", value: "Better Service Offered" },
        { text: "Life Event", value: "Life Event" },
        { text: "Switched Plan", value: "Switched Plan" },
        { text: "Others", value: "Others" },
      ]
    }
  },
  validations () {
    if (this.term_reason.value  == 'Others') {
      return {
        term_reason: {
          required
        },
        other_reason: {
          required
        },
        term_date: {
          required
        },
        plan_id: {
          required
        }
      }
    } else {
      return {
        term_reason: {
          required
        },
        term_date: {
          required
        },
        plan_id: {
          required
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("onCloseModal");
      this.$bvModal.hide("memberPlanTerminationModel");
    },
    getTermDate() {
      let app = this;
      app.emptyField();
      const url = `v2/term-request/get?policy_id=${app.member.policy_id}`
      let loader = app.$loading.show({
        container: app.$refs.planTerminationContainer
      });
      return axios
          .get(url)
          .then((response) => {
            app.term_dates = response.data.data.term_request_date;loader.hide();
            loader.hide();
          });
    },
    emptyField () {
      let app = this;
      app.term_dates = [],
          app.term_reason = "",
          app.other_reason = "",
          app.term_date = "",
          app.plan_id = []
      app.$v.$reset();
    },
    submitTermination () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      } else {
        const url = `v2/term-request/submit`;
        let loader = this.$loading.show({
          container: this.$refs.sendEmailContainer,
        });
        let terminateReq = {
          'policy_id': this.member.policy_id,
          'term_reason': this.term_reason.value  == 'Others'  ? this.other_reason : this.term_reason.value,
          'term_date': this.term_date,
          'plan_id':  this.plan_id
        };
        Api.post(url, terminateReq)
            .then((res) => {
              this.$notify(res.data.message);
              this.closeModal();
              this.reset();
            })
            .catch((err) => {
              this.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              loader.hide();
            });
      }
    }
  }
}
</script>

<style>
.is-check-box-valid {
  color: #28a745;
}
.is-valid:focus {
  border: 1px solid #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 25%);
}
.form-invalid {
  border: 1px solid #dc3545 !important;
}
.form-invalid:focus {
  border: 1px solid #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
}
.form-error {
  color: #dc3545;
}
.custom-select {
  padding: 6px;
  width: 100%;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  border-radius: 5px;
}
input:focus, textarea:focus, select:focus{
  outline: none;
}
.textarea {
  width: 100%;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  padding: 14px 12px;
  border-radius: 5px;
  overflow-y: scroll;
  height: 100px;
  resize: none;
}
.required:after {
  content:" *";
  color: #dc3545;
}
.tick-icon {
  position: relative;
  float: right;
  margin-right: 19px;
  margin-top: -27px;
  z-index: 2;
  color: #28a745;
}
input[type="checkbox"]:checked {
  accent-color: #28a745 !important;
}
.custom-check {
  -webkit-appearance:none;
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #dc3545;
}
.others-check-align {
  position: relative;
  top: -72px;
}
.check-with-label:checked + .label-for-check {
  color: #28a745 !important;
}
</style>
