var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "memberDocModal",
      "modal-class": "client-details-modal",
      "centered": "",
      "size": "lg",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": _vm.getData
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    ref: "memberDocContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_vm.user ? _c('h6', [_vm._v(" " + _vm._s(_vm.user.name) + " - " + _vm._s(_vm.member && _vm.member.policy_id ? _vm.member.policy_id : "") + " ")]) : _vm._e(), _c('p', {
    staticClass: "email-address"
  }, [_vm._v(_vm._s(_vm.user.email))]), _c('ul', {
    staticClass: "client-docs"
  }, [!_vm.files ? _c('li', [_c('no-content', {
    attrs: {
      "text": "No files found."
    }
  })], 1) : _vm._e(), _vm._l(_vm.files, function (file, index) {
    return _c('li', {
      key: index
    }, [_c('b-form-checkbox', {
      attrs: {
        "id": "checkbox-1",
        "name": "checkbox-1",
        "value": file
      },
      model: {
        value: _vm.form.data,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "data", $$v);
        },
        expression: "form.data"
      }
    }), _c('a', {
      attrs: {
        "href": file.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(file.filename))])], 1);
  })], 2), _vm.checkPlan() ? _c('p', {
    staticClass: "new-rates"
  }, [_vm._v(" New Rate as of January 1, 2024 - "), _c('router-link', {
    staticClass: "route-link",
    staticStyle: {
      "display": "block",
      "margin-bottom": "4%"
    },
    attrs: {
      "target": "_blank",
      "to": '/document-view-details/' + _vm.base64Encode(_vm.member)
    }
  }, [_vm._v("Click here to view.")])], 1) : _vm._e(), _c('button', {
    staticClass: "btn univ-btn btn-secondary",
    attrs: {
      "type": "button",
      "disabled": _vm.isDisable
    },
    on: {
      "click": _vm.sendCardEmail
    }
  }, [_vm._v(" " + _vm._s(_vm.isSend ? " Sending Card" : " Send Card") + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }