<template>
  <b-modal
    id="memberResendEmailModal"
    modal-class="client-details-modal"
    centered
    size="md"
    hide-footer
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button
          variant="close-btn"
          @click="closeModal"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid ref="resendEmailContainer">
      <div class="view-clients-modal-content">
        <h6>
          Re-Send Confirmation # {{ member ? member.policy_id : "" }} Receipt
        </h6>
        <button
          type="button"
          class="btn univ-btn btn-secondary"
          @click.prevent="sendResendEmail"
        >
          {{
            isSend
              ? "Re-Sending Confirmation Receipt"
              : "Re-Send Confirmation Receipt"
          }}
        </button>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "MemberResendEmailModal",
  data: () => ({
    isSend: false,
  }),
  props: {
    member: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("memberResendEmailModal");
      this.$emit("onClosedModal");
    },
    sendResendEmail() {
      const url = `v2/send-signature-and-upload-reminder-email?policy_id=${this.member.policy_id}&type=confirmation_receipt`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.resendEmailContainer,
      });
      Api.post(url)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
  },
};
</script>
<style scoped>
.univ-btn {
  padding: 10px;
}
</style>