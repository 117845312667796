<template>
  <b-modal
    id="memberDocModal"
    modal-class="client-details-modal"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
    @shown="getData"
  >
    <template #modal-header>
      <div class="view-clients-header">
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid ref="memberDocContainer">
      <div class="view-clients-modal-content">
        <h6 v-if="user">
          {{ user.name }} -
          {{ member && member.policy_id ? member.policy_id : "" }}
        </h6>
        <p class="email-address">{{ user.email }}</p>
        <ul class="client-docs">
          <li v-if="!files">
            <no-content text="No files found." />
          </li>
          <li v-for="(file, index) in files" :key="index">
            <b-form-checkbox
              id="checkbox-1"
              name="checkbox-1"
              v-model="form.data"
              :value="file"
            />
            <a :href="file.url" target="_blank">{{ file.filename }}</a>
          </li>
        </ul>
        <p class="new-rates" v-if="checkPlan()">
          New Rate as of January 1, 2024 -
          <router-link
          target="_blank"
            style="display:block;margin-bottom:4%"
            class="route-link"
            :to="'/document-view-details/' + base64Encode(member)"
            >Click here to view.</router-link
          >
        </p>

        <button
          type="button"
          class="btn univ-btn btn-secondary"
          @click="sendCardEmail"
          :disabled="isDisable"
        >
          {{ isSend ? " Sending Card" : " Send Card" }}
        </button>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "MemberDocModal",
  data: () => ({
    files: [],
    user: "",
    isSend: false,
    form: {
      policy_id: "",
      data: [],
    },
    isDisable: true,
    emailContent:{
      email_message_configuration_name: "MEMBER_CARD_CONFIGURATION",
      toAddress: [],    //member email
      ccAddress: [] ,   //agent email
      bccAddress:[],
      subject:"Important Member Benefit Information",
      attachedFiles:[],
      generalTemplateData:[],
      contentData:{
        member:"", //member name
        cards:""
      },
    }
  }),
  props: {
    member: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.files = [];
      this.user = "";
      this.form = {
        policy_id: "",
        data: [],
      };
      this.$emit("onCloseModal");
      this.$bvModal.hide("memberDocModal");
    },
    sendCardEmail() {
      const url = `v2/send-card-email`;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.memberDocContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
    getData() {
      const url = `v2/get-client-card-docs/${this.member.policy_id}`;
      let loader = this.$loading.show({
        container: this.$refs.memberDocContainer,
      });
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.files = data.files;
          this.user = data.user_details;
          this.form.policy_id = this.member ? this.member.policy_id : "";
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    base64Encode(member) {
      let user_id = member?.user_id;
      return btoa(user_id);
    },
    checkPlan() {
      let status = false
      this.member?.plan_details?.map((plan) => {
        if (plan.plan_status == "ACTIVE" && plan.plan_type == "MM") {
          if (plan.carrier_id == 77) {
            status = false;
          } else {
            status = true;
          }
        }
      });
      return status;
    },
     getContentData(){
      let postData = {...this.emailContent};
      this.form.data.forEach((file)=>{
          postData.contentData.cards += `<a href="${file.url}" target="_blank" style="margin-top: 10px; margin-left: 5px;">${file.filename}</a>`;
          postData.contentData.cards += '<br>';
      })
      postData.toAddress = [this.member.user_email];
      postData.contentData.member = this.member.first_name + ' ' + this.member.last_name;
      return postData
    },
    // sendCard(){
    //   const url = this.$endpoint.API_MESSAGE_CENTER + `v1/send-email-with-content-data`;
    //   let contentData = this.getContentData();
    //   this.isSend = true;
    //   let loader = this.$loading.show({
    //     container: this.$refs.memberDocContainer,
    //   });
    //   axios.post(url, contentData)
    //     .then((res) => {
    //       this.$notify(res.data.message);
    //       this.closeModal();
    //     })
    //     .catch((err) => {
    //       this.$notify(err.response.data.message, "error");
    //     })
    //     .finally(() => {
    //       this.isSend = false;
    //       loader.hide();
    //     }); 
    // }
  },
  watch: {
    // member: function (value) {
    //   if (value) {
    //     this.getData();
    //   }
    // },
    form: {
      handler: function(value) {
        if (value.policy_id && value.data.length > 0) {
          this.isDisable = false;
        } else {
          this.isDisable = true;
        }
      },
      deep: true,
    },
  },
};
</script>
