<template>
  <b-modal
    id="memberPaymentHistoryModal"
    modal-class="client-details-modal payment-history"
    centered
    size="md"
    hide-footer
    v-model="showPaymentModal"
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="view-clients-header">
        <h6>Payment History</h6>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content p-4">
        <label><strong>Payment Dates</strong></label>
        <multiselect
          v-model="paymentDates"
          :options="dateLists"
          :max-height="100"
          :searchable="true"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Choose dates"
          :multiple="true"
        ></multiselect>
      </div>
      <div class="form-group text-center mb-3 mt-3">
        <b-button
          class="univ-btn"
          type="submit"
          @click="downloadPaymentHistory"
        >
          <template v-if="isDownload"> Downloading ... </template>
          <template v-else> Download </template>
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";
export default {
  name: "MemberPaymentHistoryModal",
  props: {
    member: {
      required: true,
    },
    showPaymentModal: {
      required: true,
    },
  },
  data: () => ({
    isDownload: false,
    paymentDates: [],
    dateLists: [],
  }),
  mounted() {
    this.getDates(this.member.enrollment_date, new Date());
  },
  methods: {
    getDates(startDate, stopDate) {
      this.dateLists = [];
      let current = moment(startDate);
      let end = moment(stopDate);
      while (current <= end) {
        this.dateLists.push(moment(current).format("YYYY-MMM"));
        current = moment(current).add(1, "months");
      }
      this.dateLists.reverse();
    },
    closeModal() {
      this.paymentDates = [];
      this.$emit("update:showPaymentModal", false);
      this.$emit("onClosedModal");
    },
    downloadPaymentHistory() {
      this.isDownload = true;
      const url = "v3/get-payment-history";
      let loader = this.$loading.show({
        container: this.$refs.downloadPaymentHistoryContainer,
      });
      Api.post(url, {
        policy_id: this.member.policy_id,
        paymentDates: this.paymentDates,
      })
        .then((res) => {
          if (res.data.data.file_url) {
            let file = res.data.data.file_url;
            window.open(file, "_blank");
          }
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isDownload = false;
          loader.hide();
        });
    },
  },
  watch: {
    member: {
      deep: true,
      handler(val) {
        if (val && val.enrollment_date)
          this.getDates(val.enrollment_date, new Date());
      },
    },
  },
};
</script>
<style scoped>
.payment-history .modal-content .modal-body {
  min-height: 300px !important;
}
.multiselect {
  width: auto !important;
  margin: auto !important;
}
</style>
