var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "memberSignatureEmailModal",
      "modal-class": "client-details-modal",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    ref: "resendSignatureContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('h6', [_vm._v(" Re-Send Signature # " + _vm._s(_vm.member ? _vm.member.policy_id : "") + " Receipt ")]), _c('button', {
    staticClass: "btn univ-btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.sendSignatureEmail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isSend ? "Re-Sending Signature Receipt" : "Re-Send Signature Receipt") + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }