var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "memberPlanTerminationModel",
      "modal-class": "client-details-modal",
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "shown": function shown($event) {
        return _vm.getTermDate();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    ref: "planTerminationContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('h5', [_vm._v("Terminate Plan ")]), _c('hr'), _c('div', {
    staticClass: "addMessage-form"
  }, [_c('div', [_c('label', {
    staticClass: "required"
  }, [_vm._v("Term Effective Date ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.term_date,
      expression: "term_date"
    }],
    staticClass: "custom-select  mt-2",
    class: {
      'form-invalid': _vm.$v.term_date.$error,
      'is-valid': _vm.term_date && !_vm.$v.term_date.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.term_date = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v(" Select Date")]), _vm._l(_vm.term_dates, function (dateList, index) {
    return _c('option', {
      key: index
    }, [_vm._v(_vm._s(dateList))]);
  })], 2)]), _c('span', {
    class: {
      'fa fa-check tick-icon': _vm.term_date && !_vm.$v.term_date.$error
    }
  }), !_vm.$v.term_date.required && _vm.$v.term_date.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Date is required ")]) : _vm._e()])]), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "required"
  }, [_vm._v(" Select Plan(S)")]), _vm.member ? _c('div', _vm._l(_vm.member.plan_details, function (option, index) {
    return _c('div', {
      key: index
    }, [option.plan_status == 'ACTIVE' && option.is_assoc == 0 ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.plan_id,
        expression: "plan_id"
      }],
      staticClass: "mr-3 check-with-label",
      class: {
        'custom-check': _vm.$v.plan_id.$error
      },
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": option.plan_id,
        "checked": Array.isArray(_vm.plan_id) ? _vm._i(_vm.plan_id, option.plan_id) > -1 : _vm.plan_id
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.plan_id,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = option.plan_id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.plan_id = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.plan_id = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.plan_id = $$c;
          }
        }
      }
    }) : _vm._e(), option.plan_status == 'ACTIVE' && option.is_assoc == 0 ? _c('label', {
      staticClass: "label-for-check",
      class: {
        'form-error': _vm.$v.plan_id.$error
      },
      staticStyle: {
        "margin-left": "8px"
      }
    }, [_vm._v(_vm._s(option.plan_name_system_new) + " ")]) : _vm._e()]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "mt-3"
  }, [_c('label', {
    staticClass: "required"
  }, [_vm._v(" Reasons ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.term_reason,
      expression: "term_reason"
    }],
    staticClass: "custom-select mt-2",
    class: {
      'form-invalid': _vm.$v.term_reason.$error,
      'is-valid': _vm.term_reason && !_vm.$v.term_reason.$error
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.term_reason = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": ""
    }
  }, [_vm._v(" Select Reason")]), _vm._l(_vm.reasons, function (reason, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": {
          value: reason.value,
          text: reason.text
        }
      }
    }, [_vm._v(_vm._s(reason.text))]);
  })], 2), _c('span', {
    class: {
      'fa fa-check tick-icon': _vm.term_reason && !_vm.$v.term_reason.$error
    }
  }), !_vm.$v.term_reason.required && _vm.$v.term_reason.$error ? _c('div', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Reason is required ")]) : _vm._e(), _vm.term_reason.value == 'Others' ? _c('div', [_vm.term_reason.value == 'Others' ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.other_reason,
      expression: "other_reason"
    }],
    staticClass: "mt-4 textarea",
    class: {
      'form-invalid': _vm.$v.other_reason.$error,
      'is-valid': _vm.term_reason && !_vm.$v.other_reason.$error
    },
    attrs: {
      "placeholder": "Enter Reason"
    },
    domProps: {
      "value": _vm.other_reason
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.other_reason = $event.target.value;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('span', {
    class: {
      'fa fa-check tick-icon others-check-align': _vm.other_reason && !_vm.$v.other_reason.$error
    }
  }), !_vm.$v.other_reason.required && _vm.$v.other_reason.$error ? _c('span', {
    staticClass: "form-error text-left ml-3 mt-2"
  }, [_vm._v(" Reason is required ")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "row justify-content-md-end  mt-4"
  }, [_c('div', {
    staticClass: "col-xl-3"
  }, [_c('button', {
    staticClass: "univ-btn text-center",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("Cancel ")])]), _c('div', {
    staticClass: "col-xl-3"
  }, [_c('button', {
    staticClass: "univ-btn text-center",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitTermination
    }
  }, [_vm._v(" Submit ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }