<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>System ID</th>
          <th>Name</th>
          <th>Dates</th>
          <th>Rep & Employee</th>
          <th>Premiums</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-if="!memberLength" colspan="6" align="center">
            No records found.
          </td>
        </tr>
        <tr
          :class="calssByStatus(item.status)"
          :id="item.status"
          v-for="(item, index) in memberLists.records"
          :key="index"
        >
          <td>
            <strong>{{ item.policy_id }}</strong>
            <br />
            <span :class="'id-status-' + item.status.toLowerCase()">{{
              item.status
            }}</span>
          </td>
          <td>
            <strong>{{ item.first_name }} {{ item.last_name }}</strong>
            <br />
            <span v-if="item.phone">
              {{ item.phone | us_phone }}
              <valid-icon
                :is-valid="item.is_phone_valid"
                className="valid-icon"
              />
            </span>
            <br />
            <span v-if="item.user_email">
              {{ item.user_email }}
              <valid-icon
                :is-valid="item.is_email_valid"
                className="valid-icon"
              />
            </span>
            <p>{{ item.user_address }}</p>
          </td>
          <td>
            <strong>Enrollment Date:</strong>
            <br />
            {{ item.enrollment_date }}
            <br />
            <strong>Effective Date:</strong>
            <br />
            {{ item.effective_date }}
            <br />
            <template v-if="item.billing_date">
              <strong>Billing Date: </strong><br />
              {{ item.billing_date }}
            </template>
            <template v-if="item.paid_through_date != 'N/A'">
              <br />
              <strong>Pdth Date:</strong> <br />
              {{ item.paid_through_date }}
            </template>
          </td>
          <td>
            <strong>
              <router-link
                :to="{
                  name: 'MemberListByRep',
                  params: { repId: encodedId(item.agent_id) },
                }"
                target="_blank"
                class="route-link"
              >
                {{ item.rep_first_name }} {{ item.rep_last_name }}
              </router-link>
            </strong>
            <br />
            {{ item.agent_code }}
            <br />
            <template v-if="item.employer_name">
              Employer Name:
              <router-link
                :to="{
                  name: 'MemberListByGroup',
                  params: { groupId: encodedId(item.employer_id) },
                }"
                target="_blank"
                class="route-link"
              >
                {{ item.employer_name }}
              </router-link>
            </template>
          </td>
          <td>
            <table class="table premiums-table">
              <tr v-for="(planItem, key) in item.plan_details" :key="key">
                <td
                  :class="
                    planItem.plan_status == 'TERMED' ||
                    planItem.plan_status == 'WITHDRAWN'
                      ? 'text-red'
                      : ''
                  "
                >
                  {{ planItem.plan_name_system_new }}
                  <template
                    v-if="
                      planItem.plan_status == 'TERMED' ||
                      planItem.plan_status == 'WITHDRAWN'
                    "
                  >
                    <br />
                    {{ `(${planItem.plan_termed_date})` }}
                  </template>
                </td>
                <td>{{ planItem.plan_effective_date }}</td>
                <td>{{ "$" + planItem.plan_price }}</td>
              </tr>
              <tr v-if="item.cc_fee > 0">
                <td>
                  <strong>Payment Service Fee</strong>
                </td>
                <td></td>
                <td>
                  <strong>{{ "$" + item.cc_fee }}</strong>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Recurring Monthly</strong>
                </td>
                <td></td>
                <td>
                  <strong>{{ "$" + item.recurring_amount }}</strong>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <div class="action-icons">
              <custom-route-button
                title="View Details"
                iconClass="fas fa-info"
                :routeLink="{
                  name: 'MemberPolicyDetail',
                  params: { policyId: encodedId(item.policy_id) },
                }"
              />
              <custom-button
                title="View Docs"
                iconClass="fas fa-file"
                v-if="item.actions.view_docs"
                @onClicked="openModal(item, 'memberDocModal')"
              />

              <custom-button
                title="Re-send Confirmation Receipt"
                iconClass="fas fa-paper-plane"
                @onClicked="openModal(item, 'memberResendEmailModal')"
              />

              <custom-button
                title="Send Email To This Member"
                iconClass="fas fa-envelope"
                v-if="item.actions.send_email_member"
                @onClicked="openModal(item, 'memberEmailModal')"
              />

              <custom-button
                title="Send Reg/Login Email"
                iconClass="fas fa-lock"
                v-if="item.actions.send_login == 1"
                @onClicked="openModal(item, 'memberSendLoginEmailModal')"
              />
              <custom-button
                title="Representative Enrollment"
                iconClass="fas fa-clipboard-list"
                v-if="item.actions.enrollment_process == 'rep'"
              />

              <custom-button
                title="Payment History"
                iconClass="fas fa-dollar-sign"
                v-if="item.actions.payment_history == 1"
                @onClicked="openPaymentHistoryModal(item)"
              />

              <custom-button
                title="Applicant Enrollment"
                iconClass="fas fa-male"
                v-if="item.actions.enrollment_process == 'applicant'"
              />

              <a
                href="javascript:void(0);"
                @click="memberBenefitStoreUrl(item.user_id)"
                v-b-tooltip.hover
                title="Benefit Store"
              >
                <img
                  src="@/assets/images/benefit-logo.svg"
                  width="26px"
                  alt=""
                />
              </a>
                <custom-button
                v-if="checkPlan(item.plan_details) && item.actions.show_IHA_plan"
                title="Change Medical Plan"
                iconClass="fas fa-light fa-book-medical"
                @onClicked="openModal(item, 'memberChangeMajorMedicalModal')"
              />

                <custom-button
                v-if="checkPlan(item.plan_details) && item.status == 'PENDING' && item.actions.show_IHA_plan"
                title="Approve Plan"
                iconClass="fas fa-light fa-check"
                @onClicked="openModal(item, 'approvePlanModal')"
              />

              <template v-if="item.actions.is_signature_required == 1">
                <custom-button
                  v-if="item.actions.signature_complete != 1"
                  title="Signature Not Received"
                  iconClass="fas fa-signature"
                  className="sign-incomplete"
                  @onClicked="openModal(item, 'memberSignatureEmailModal')"
                />
                <custom-button
                  v-if="item.actions.signature_complete == 1"
                  title="Signature Received"
                  className="sign-complete"
                  iconClass="fas fa-signature "
                />

              </template>
              <!-- <custom-button
                title="Effective Policy Date"
                iconClass="fas fa-calendar-day"
                @onClicked="openModal(item, 'policyDateModal')"
              /> -->
              <custom-button
                v-if="item.actions.show_default_otp"
                title="View Default OTP"
                iconClass="fas fa-list"
                @onClicked="openModal(item, 'defaultOtpModal')"
              />
              <custom-button
                title="Change Platform"
                iconClass="fas fa-toolbox"
                @onClicked="openModal(item, 'memberPlatformUpdateModal')"
              />
              <custom-button
                  title="Terminate Plan"
                  iconClass="fas fa-ban"
                  className="sign-incomplete"
                  v-if="item.status == 'ACTIVE'"
                  @onClicked="openModal(item, 'memberPlanTerminationModel')"
              />
              <a
                class="cursor-pointer referral-btn"
                v-b-tooltip.hover
                title="Send Referral"
                @click="openModal(item, 'sendReferralLinkToUser')"
              >
                <img
                  src="@/assets/images/referral.svg"
                  width="16px"
                  alt=""
                />
              </a>
              <button
                  title="View Messages"
                  class="custom-btn msg-align"
                  v-b-tooltip.hover="ViewMsg"
                  @click="routeToViewMessage(item)"
              >
                <img  src="../../../assets/images/view-msg.svg"/>
              </button>
            </div>
            <p v-if="item.eip">EIP: {{ item.eip }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <member-doc-modal :member.sync="row" @onCloseModal="row = null" />
    <member-email-modal :member.sync="row" @onCloseModal="row = null" />
    <member-resend-email-modal :member.sync="row" @onCloseModal="row = null" />
    <member-signature-email-modal
      :member.sync="row"
      @onCloseModal="row = null"
    />
    <member-payment-history-modal
      :member.sync="row"
      @onCloseModal="row = null"
      :showPaymentModal.sync="showPaymentModal"
    />
    <member-change-platform-modal
      :member.sync="row"
      @onCloseModal="row = null"
    />
    <member-send-login-email-modal
      :member.sync="row"
      @onCloseModal="row = null"
    />
    <member-change-major-medical-modal
      :member.sync="row"
      @onCloseModal="row = null"
    />
    <approve-plan-modal
      :member.sync="row"
      @onCloseModal="row = null"
    />
    <effective-policy-date-modal
      :member.sync="row"
      @onCloseModal="row = null" />
    <default-otp-modal :row.sync="row" rowType="M" @onCloseModal="row = null" />
    <member-plan-termination-modal :member.sync="row"   @onCloseModal="row = null"/>
    <send-referral-link-to-user :member.sync="row" />
  </div>
</template>
<script>
import MemberDocModal from "../modal/MemberDocModal.vue";
import MemberEmailModal from "../modal/MemberEmailModal.vue";
import MemberResendEmailModal from "../modal/MemberResendEmailModal.vue";
import MemberSignatureEmailModal from "../modal/MemberSignatureEmailModal.vue";
import MemberPaymentHistoryModal from "../modal/MemberPaymentHistoryModal.vue";
import MemberChangePlatformModal from "../modal/MemberChangePlatformModal.vue";
import MemberSendLoginEmailModal from "../modal/MemberSendLoginEmailModal.vue";
import MemberChangeMajorMedicalModal from "../modal/MemberChangeMajorMedicalModal.vue";
import ApprovePlanModal from "../modal/ApprovePlanModal.vue";
import EffectivePolicyDateModal from '../modal/EffectivePolicyDateModal.vue'
import DefaultOtpModal from "@/components/ui/DefaultOtpModal.vue";
import MemberPlanTerminationModal from "../modal/MemberPlanTerminationModal.vue";
import SendReferralLinkToUser from "../modal/SendReferralLinkToUser";
import {mapGetters} from "vuex"
export default {
  name: "ClientsData",
  components: {
    MemberDocModal,
    MemberEmailModal,
    MemberResendEmailModal,
    MemberSendLoginEmailModal,
    MemberSignatureEmailModal,
    MemberPaymentHistoryModal,
    MemberChangeMajorMedicalModal,
    ApprovePlanModal,
    EffectivePolicyDateModal,
    DefaultOtpModal,
    MemberChangePlatformModal,
    MemberPlanTerminationModal,
    SendReferralLinkToUser
  },
  data: () => ({
    row: null,
    showPaymentModal: false,
  }),
  props: {
    memberLists: {
      required: false,
    },
  },
  computed: {
    ...mapGetters({ isVipUser: "vipUser" }),
    memberLength() {
      return this.memberLists?.records?.length;
    },
  },
  methods: {
    memberBenefitStoreUrl(memberId) {
      const benefitStoreUrl = this.$endpoint.BENEFIT_STORE_URL;
      let url = `${benefitStoreUrl}/member-home?uid=${btoa(memberId)}`;
      window.open(url, "_blank");
    },
    openPaymentHistoryModal(row) {
      this.row = row;
      this.showPaymentModal = true;
    },
    openModal(row, modalId) {
      this.row = row;
      this.$bvModal.show(modalId);
    },
    encodedId(id) {
      return btoa(id);
    },
    calssByStatus(status) {
      return status.toLowerCase() + "_status_row";
    },
    filterInitalState() {
      return {
        effective_date: "",
      };
    },
    checkPlan(planDetails) {
      let status = false
      planDetails?.map((plan) => {
        if (plan.plan_status == "ACTIVE" && plan.plan_type == "MM" && plan.carrier_id == "77") {
          status = true;
        }
      });
      return status;
    },
    routeToViewMessage (row) {
      let app = this
      let userInfo = {
        'user_message_type': ''
      }
      userInfo.user_id = row.user_id
      userInfo.user_message_type = 'M'
      app.$store.commit("setViewMessageUserInfo", userInfo);
      app.$router.push('/view-msg')
    },
  },
};
</script>
<style scoped>
.valid-icon {
  width: 5%;
}
.route-link {
  color: #03c3ec;
}
.referral-btn{
  background: transparent linear-gradient(180deg,#cdc462, #BF953F,  #AA771C) 0 0 no-repeat padding-box;
}
.msg-align {
  position: relative;
  top: -1px;
}
</style>
