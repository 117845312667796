var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [_c('tr', [!_vm.memberLength ? _c('td', {
    attrs: {
      "colspan": "6",
      "align": "center"
    }
  }, [_vm._v(" No records found. ")]) : _vm._e()]), _vm._l(_vm.memberLists.records, function (item, index) {
    return _c('tr', {
      key: index,
      class: _vm.calssByStatus(item.status),
      attrs: {
        "id": item.status
      }
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(item.policy_id))]), _c('br'), _c('span', {
      class: 'id-status-' + item.status.toLowerCase()
    }, [_vm._v(_vm._s(item.status))])]), _c('td', [_c('strong', [_vm._v(_vm._s(item.first_name) + " " + _vm._s(item.last_name))]), _c('br'), item.phone ? _c('span', [_vm._v(" " + _vm._s(_vm._f("us_phone")(item.phone)) + " "), _c('valid-icon', {
      attrs: {
        "is-valid": item.is_phone_valid,
        "className": "valid-icon"
      }
    })], 1) : _vm._e(), _c('br'), item.user_email ? _c('span', [_vm._v(" " + _vm._s(item.user_email) + " "), _c('valid-icon', {
      attrs: {
        "is-valid": item.is_email_valid,
        "className": "valid-icon"
      }
    })], 1) : _vm._e(), _c('p', [_vm._v(_vm._s(item.user_address))])]), _c('td', [_c('strong', [_vm._v("Enrollment Date:")]), _c('br'), _vm._v(" " + _vm._s(item.enrollment_date) + " "), _c('br'), _c('strong', [_vm._v("Effective Date:")]), _c('br'), _vm._v(" " + _vm._s(item.effective_date) + " "), _c('br'), item.billing_date ? [_c('strong', [_vm._v("Billing Date: ")]), _c('br'), _vm._v(" " + _vm._s(item.billing_date) + " ")] : _vm._e(), item.paid_through_date != 'N/A' ? [_c('br'), _c('strong', [_vm._v("Pdth Date:")]), _vm._v(" "), _c('br'), _vm._v(" " + _vm._s(item.paid_through_date) + " ")] : _vm._e()], 2), _c('td', [_c('strong', [_c('router-link', {
      staticClass: "route-link",
      attrs: {
        "to": {
          name: 'MemberListByRep',
          params: {
            repId: _vm.encodedId(item.agent_id)
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.rep_first_name) + " " + _vm._s(item.rep_last_name) + " ")])], 1), _c('br'), _vm._v(" " + _vm._s(item.agent_code) + " "), _c('br'), item.employer_name ? [_vm._v(" Employer Name: "), _c('router-link', {
      staticClass: "route-link",
      attrs: {
        "to": {
          name: 'MemberListByGroup',
          params: {
            groupId: _vm.encodedId(item.employer_id)
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(item.employer_name) + " ")])] : _vm._e()], 2), _c('td', [_c('table', {
      staticClass: "table premiums-table"
    }, [_vm._l(item.plan_details, function (planItem, key) {
      return _c('tr', {
        key: key
      }, [_c('td', {
        class: planItem.plan_status == 'TERMED' || planItem.plan_status == 'WITHDRAWN' ? 'text-red' : ''
      }, [_vm._v(" " + _vm._s(planItem.plan_name_system_new) + " "), planItem.plan_status == 'TERMED' || planItem.plan_status == 'WITHDRAWN' ? [_c('br'), _vm._v(" " + _vm._s("(".concat(planItem.plan_termed_date, ")")) + " ")] : _vm._e()], 2), _c('td', [_vm._v(_vm._s(planItem.plan_effective_date))]), _c('td', [_vm._v(_vm._s("$" + planItem.plan_price))])]);
    }), item.cc_fee > 0 ? _c('tr', [_vm._m(1, true), _c('td'), _c('td', [_c('strong', [_vm._v(_vm._s("$" + item.cc_fee))])])]) : _vm._e(), _c('tr', [_vm._m(2, true), _c('td'), _c('td', [_c('strong', [_vm._v(_vm._s("$" + item.recurring_amount))])])])], 2)]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [_c('custom-route-button', {
      attrs: {
        "title": "View Details",
        "iconClass": "fas fa-info",
        "routeLink": {
          name: 'MemberPolicyDetail',
          params: {
            policyId: _vm.encodedId(item.policy_id)
          }
        }
      }
    }), item.actions.view_docs ? _c('custom-button', {
      attrs: {
        "title": "View Docs",
        "iconClass": "fas fa-file"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberDocModal');
        }
      }
    }) : _vm._e(), _c('custom-button', {
      attrs: {
        "title": "Re-send Confirmation Receipt",
        "iconClass": "fas fa-paper-plane"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberResendEmailModal');
        }
      }
    }), item.actions.send_email_member ? _c('custom-button', {
      attrs: {
        "title": "Send Email To This Member",
        "iconClass": "fas fa-envelope"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberEmailModal');
        }
      }
    }) : _vm._e(), item.actions.send_login == 1 ? _c('custom-button', {
      attrs: {
        "title": "Send Reg/Login Email",
        "iconClass": "fas fa-lock"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberSendLoginEmailModal');
        }
      }
    }) : _vm._e(), item.actions.enrollment_process == 'rep' ? _c('custom-button', {
      attrs: {
        "title": "Representative Enrollment",
        "iconClass": "fas fa-clipboard-list"
      }
    }) : _vm._e(), item.actions.payment_history == 1 ? _c('custom-button', {
      attrs: {
        "title": "Payment History",
        "iconClass": "fas fa-dollar-sign"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openPaymentHistoryModal(item);
        }
      }
    }) : _vm._e(), item.actions.enrollment_process == 'applicant' ? _c('custom-button', {
      attrs: {
        "title": "Applicant Enrollment",
        "iconClass": "fas fa-male"
      }
    }) : _vm._e(), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": "javascript:void(0);",
        "title": "Benefit Store"
      },
      on: {
        "click": function click($event) {
          return _vm.memberBenefitStoreUrl(item.user_id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/benefit-logo.svg"),
        "width": "26px",
        "alt": ""
      }
    })]), _vm.checkPlan(item.plan_details) && item.actions.show_IHA_plan ? _c('custom-button', {
      attrs: {
        "title": "Change Medical Plan",
        "iconClass": "fas fa-light fa-book-medical"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberChangeMajorMedicalModal');
        }
      }
    }) : _vm._e(), _vm.checkPlan(item.plan_details) && item.status == 'PENDING' && item.actions.show_IHA_plan ? _c('custom-button', {
      attrs: {
        "title": "Approve Plan",
        "iconClass": "fas fa-light fa-check"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'approvePlanModal');
        }
      }
    }) : _vm._e(), item.actions.is_signature_required == 1 ? [item.actions.signature_complete != 1 ? _c('custom-button', {
      attrs: {
        "title": "Signature Not Received",
        "iconClass": "fas fa-signature",
        "className": "sign-incomplete"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberSignatureEmailModal');
        }
      }
    }) : _vm._e(), item.actions.signature_complete == 1 ? _c('custom-button', {
      attrs: {
        "title": "Signature Received",
        "className": "sign-complete",
        "iconClass": "fas fa-signature "
      }
    }) : _vm._e()] : _vm._e(), item.actions.show_default_otp ? _c('custom-button', {
      attrs: {
        "title": "View Default OTP",
        "iconClass": "fas fa-list"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'defaultOtpModal');
        }
      }
    }) : _vm._e(), _c('custom-button', {
      attrs: {
        "title": "Change Platform",
        "iconClass": "fas fa-toolbox"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberPlatformUpdateModal');
        }
      }
    }), item.status == 'ACTIVE' ? _c('custom-button', {
      attrs: {
        "title": "Terminate Plan",
        "iconClass": "fas fa-ban",
        "className": "sign-incomplete"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(item, 'memberPlanTerminationModel');
        }
      }
    }) : _vm._e(), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "cursor-pointer referral-btn",
      attrs: {
        "title": "Send Referral"
      },
      on: {
        "click": function click($event) {
          return _vm.openModal(item, 'sendReferralLinkToUser');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/referral.svg"),
        "width": "16px",
        "alt": ""
      }
    })]), _c('button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.ViewMsg,
        expression: "ViewMsg",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "custom-btn msg-align",
      attrs: {
        "title": "View Messages"
      },
      on: {
        "click": function click($event) {
          return _vm.routeToViewMessage(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../../assets/images/view-msg.svg")
      }
    })])], 2), item.eip ? _c('p', [_vm._v("EIP: " + _vm._s(item.eip))]) : _vm._e()])]);
  })], 2)]), _c('member-doc-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-email-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-resend-email-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-signature-email-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-payment-history-modal', {
    attrs: {
      "member": _vm.row,
      "showPaymentModal": _vm.showPaymentModal
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      },
      "update:showPaymentModal": function updateShowPaymentModal($event) {
        _vm.showPaymentModal = $event;
      },
      "update:show-payment-modal": function updateShowPaymentModal($event) {
        _vm.showPaymentModal = $event;
      }
    }
  }), _c('member-change-platform-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-send-login-email-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-change-major-medical-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('approve-plan-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('effective-policy-date-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('default-otp-modal', {
    attrs: {
      "row": _vm.row,
      "rowType": "M"
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('member-plan-termination-modal', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('send-referral-link-to-user', {
    attrs: {
      "member": _vm.row
    },
    on: {
      "update:member": function updateMember($event) {
        _vm.row = $event;
      }
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("System ID")]), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Dates")]), _c('th', [_vm._v("Rep & Employee")]), _c('th', [_vm._v("Premiums")]), _c('th', [_vm._v("Actions")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Payment Service Fee")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('strong', [_vm._v("Recurring Monthly")])]);
}]

export { render, staticRenderFns }