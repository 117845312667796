var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "approvePlanModal",
      "modal-class": "client-details-modal",
      "centered": "",
      "size": "lg",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content"
  }, [_c('h6', [_vm._v("Approve/Reject Policy & Plans")]), _c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "approvePlanModal",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.updatePlan);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xxl-8 col-xl-8 col-lg-8 col-md-8 mb-8"
        }, [_c('div', [_c('b-form-radio', {
          attrs: {
            "value": "1",
            "name": "radio-size"
          },
          model: {
            value: _vm.is_approved,
            callback: function callback($$v) {
              _vm.is_approved = $$v;
            },
            expression: "is_approved"
          }
        }, [_vm._v("  Approve")]), _c('b-form-radio', {
          attrs: {
            "name": "radio-size",
            "value": "0"
          },
          model: {
            value: _vm.is_approved,
            callback: function callback($$v) {
              _vm.is_approved = $$v;
            },
            expression: "is_approved"
          }
        }, [_vm._v("  Reject")])], 1)]), _c('div', {
          staticClass: "form-group  mb-3 mt-3"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm.isUpdating ? [_vm._v(" Updating ... ")] : [_vm._v(" Submit")]], 2)], 1)])])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }