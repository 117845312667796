var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "memberPlatformUpdateModal",
      "modal-class": "client-details-modal platform-update",
      "centered": "",
      "size": "md",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('h4', [_vm._v("Update Platform")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "view-clients-modal-content p-4"
  }, [_c('label', {
    staticClass: "mb-2"
  }, [_c('strong', [_vm._v("Platform")])]), _c('multiselect', {
    attrs: {
      "name": "plan",
      "placeholder": "Choose Platform",
      "options": _vm.platforms.map(function (type) {
        return type.domain;
      }),
      "custom-label": function customLabel(opt) {
        return _vm.platforms.find(function (x) {
          return x.domain == opt;
        }).name;
      },
      "max-height": 130,
      "searchable": true
    },
    model: {
      value: _vm.selectedPlatform,
      callback: function callback($$v) {
        _vm.selectedPlatform = $$v;
      },
      expression: "selectedPlatform"
    }
  })], 1), _c('div', {
    staticClass: "form-group text-center mb-3 mt-3"
  }, [_c('b-button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.updatePlatform
    }
  }, [_vm.isUpdate ? [_vm._v(" Updating ... ")] : [_vm._v(" Update")]], 2)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }